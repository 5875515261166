import { Link, StaticQuery, graphql} from "gatsby"
import React from "react"
import Img from "gatsby-image"


const ServiceComponent = ({ site }) => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { glob: "**/src/pages/services/*.md" } }
          ){
          edges {
            node {
              id
              frontmatter {
                title
                path
                icon {
                  childImageSharp{
                    fixed(width:80){
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { edges } = data.allMarkdownRemark
      
      return(
      <>
        <section name="servicios"className="services" style={{top: 0}}>
          <div className="container">
            <div className="row title">
              <h1>Servicios</h1>
              <h3>Resuelve todas tus dudas con nosotros, revisa todos los servicios que brindamos.</h3>
            </div>
            <div className="row">
        {edges.map(edge =>{
          const {frontmatter} = edge.node
          return (
              <div className="col-5 animated headShake"  key={edge.node.id}>
                  <Link to={frontmatter.path}>
                    <div className="service-wrapper" >
                      <Img fixed={frontmatter.icon.childImageSharp.fixed}/>
                    </div>
                    <h4>{frontmatter.title}</h4>

                  </Link>
              </div>
          )
        })}
            </div>
          </div>
        </section>
      </>
    )}}
  />
)


export default ServiceComponent
