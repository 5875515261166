import { Link } from "gatsby"
import React from "react"
import logo from "../images/pp-icon.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faTasks, faUsers, faBriefcase,faRss,faComment } from '@fortawesome/free-solid-svg-icons'

class Header extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      scrolled: false,
    }
  }
  componentDidMount(){
    window.addEventListener('scroll',()=>{
      const isTop = window.scrollY <1100 ;
      if (isTop !== true) {
        this.setState ({ scrolled: true});
      } else {
        this.setState ({ scrolled: false})
      }
    });
  }
  componentWillUnmount(){
    window.removeEventListener('scroll',window);
  }
  render(){
    return(
      <header>
        <div className={this.state.scrolled ? 'header fixed' : 'header'}>
          <nav>
            <ul className="navbar">
                <li>
                  <Link to="/"  activeStyle={{color: "#f58220"}}>
                    <h3>
                    Inicio
                    </h3>
                  </Link>
                </li>
                <li>
                  <Link to="/#servicios" activeStyle={{color: "#f58220"}}>
                    <h3>
                    Servicios
                    </h3>
                  </Link>
                </li>
                <li>
                  <Link to="/#clientes" activeStyle={{color: "#f58220"}}>
                    <h3>
                    Clientes
                    </h3>
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <img src={logo} className="logo" alt="PyP Asesoría Integral"/>
                  </Link>
                </li>
                <li>
                  <Link to="/nosotros" activeStyle={{color: "#f58220"}}>
                    <h3>
                    Nosotros
                    </h3>
                  </Link>
                </li>
                <li>
                  <Link to="/#noticias" activeStyle={{color: "#f58220"}}>
                    <h3>
                    Noticias
                    </h3>
                  </Link>
                </li>
                <li>
                  <Link to="/#contacto" activeStyle={{color: "#f58220"}}>
                    <h3>
                    Contacto
                    </h3>
                  </Link>
                </li>
              </ul>
          </nav>
        </div>
        <div className="mobile-bottom">
          <ul>
            <li>
              <Link to="/">
                <FontAwesomeIcon icon={faHome} />
              </Link>
            </li>
            <li>
              <Link to="/#servicios">
                <FontAwesomeIcon icon={faTasks} />
              </Link>
            </li>
            <li>
              <Link to="/#clientes">
                <FontAwesomeIcon icon={faBriefcase} />
              </Link>
            </li>
            
            <li>
              <Link to="/nosotros">
                <FontAwesomeIcon icon={faUsers} />
              </Link>
            </li>
            <li>
              <Link to="/#noticias">
                <FontAwesomeIcon icon={faRss} />
              </Link>
            </li>
            <li>
              <Link to="/#contacto">
                <FontAwesomeIcon icon={faComment} />
              </Link>
            </li>
          </ul>
        </div>

      </header>
    )
  }
}


export default Header


