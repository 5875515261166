/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import "../assets/layout.scss"
import HeaderJumbo from "./headerJumbo";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp, faFacebookMessenger } from '@fortawesome/free-brands-svg-icons'

const Layout2 = ({ children }) => (
  <StaticQuery
    query={graphql`
        query {
          site {
            siteMetadata {
              title
              description
              author
            }
          }
        }
    `}
    render={data => (
      <>
      <div  className="btn-contact">
        <FontAwesomeIcon icon={faComments} />
        <ul>
          <li><a href="https://api.whatsapp.com/send?phone=51941463739" className="float" target="_blank"  rel="noopener noreferrer"><FontAwesomeIcon icon={faWhatsapp} className="whatsapp"/></a></li>
          <li><a href="https://www.facebook.com/PP-Asesoría-Integral-2197527110293724/" className="float" target="_blank"  rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebookMessenger} className="messenger"/></a></li>
        </ul>
      </div>
        <HeaderJumbo/>
        <Header/>
        <div>
          <main>{children}</main>
        </div>
        <Footer/>
      </>
    )}
  />
)


export default Layout2
