import React from 'react'
import logo from "../images/logo.png"
/* import banner from "../images/banner.jpeg"
import Img from 'gatsby-image'
import { StaticQuery, graphql } from "gatsby"
 */
const HeaderJumbo = () => (
    <div className="front-page">
      <div className="front-container">
        <img src={logo} alt="PyP Asesoria Integral"className="animated bounceInDown "/>
        <button className="animated bounceInUp slow">
            <a href="#jumbotron"><h2>Conoce más</h2></a>
        </button>
      </div>
      {/* <div className="image-wrapper">
        <picture className="background-picture">
            <source media="(min-width: 650px)" srcSet={banner} />
            <source media="(min-width: 465px)" srcSet={banner} />
            <Image />
        </picture>
      </div> */}
      <div className="darken-background"></div>
    </div>
  )
  
  /* const Image = () => (
    <StaticQuery
      query={graphql`
        query {
          placeholderImage: file(relativePath: { eq: "banner.jpeg" }) {
            childImageSharp {
              fluid(maxWidth: 2400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => <Img fluid={data.placeholderImage.childImageSharp.fluid} />}
    />
  ) */
  export default HeaderJumbo