import React from "react";
import { StaticQuery, graphql } from "gatsby"
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Img from "gatsby-image"



const CarouselContainer = () => (
  <StaticQuery
    query={graphql`
        query {
          image1: file(relativePath: { eq: "banner/photo-1.jpeg" }) {
            childImageSharp {
              fluid(maxWidth:2000){
              ...GatsbyImageSharpFluid
            }
            }
          }
          image2: file(relativePath: { eq: "banner/photo-2.jpeg" }) {
            childImageSharp {
              fluid(maxWidth:2000){
              ...GatsbyImageSharpFluid
            }
            }
          } 
          image3: file(relativePath: { eq: "banner/photo-3.jpeg" }) {
            childImageSharp {
              fluid(maxWidth:2000){
              ...GatsbyImageSharpFluid
            }
            }
          } 
          image4: file(relativePath: { eq: "banner/photo-4.jpeg" }) {
            childImageSharp {
              fluid(maxWidth:2000){
              ...GatsbyImageSharpFluid
            }
            }
          } 
          image5: file(relativePath: { eq: "banner/photo-5.jpeg" }) {
            childImageSharp {
              fluid(maxWidth:2000){
              ...GatsbyImageSharpFluid
            }
            }
          } 
        }
    `}
  render={data => (
    <>
      <Carousel autoPlay showThumbs={false}>
    <div className="slider">
      <div className="darken-background"  style={{opacity:.3}}></div>
      <div className="slider-picture">
        <Img fluid={data.image1.childImageSharp.fluid}/>
      </div>
      <div className="text">
        <h3>Implementación de Sistemas de Gestión</h3>
        <p>Somos su principal aliado estrátegico para lograr mejorar su competitividad a través de sistemas de gestión implementados eficientemente.</p>
      </div>
    </div>
    <div className="slider">
      <div className="darken-background"  style={{opacity:.3}}></div>
      <div className="slider-picture">
        <Img fluid={data.image2.childImageSharp.fluid}/>
      </div>
      <div className="text">
        <h3>Entrenamiento Coorporativo</h3>
        <p>Mejoramos las competencias de su personal a través de nuestras capacitaciones en Sistemas de Gestión.</p>
      </div>
    </div>
    <div className="slider">
    <div className="darken-background"  style={{opacity:.3}}></div>
      <div className="slider-picture">
        <Img fluid={data.image3.childImageSharp.fluid}/>
      </div>
      <div className="text">
        <h3>Mejora Continua</h3>
        <p>Nuestra motivación se centra en ir mejorando los Sistemas de Gestión implementados en las organizaciones.</p>
      </div>
    </div>
    <div className="slider">
    <div className="darken-background"  style={{opacity:.3}}></div>
      <div className="slider-picture">
        <Img fluid={data.image4.childImageSharp.fluid}/>
      </div>
      <div className="text">
        <h3>Asesoría Legal</h3>
        <p>Ofrecemos el soporte legal necesario para organizaciones competitivas.</p>
      </div>
    </div>
    <div className="slider">
    <div className="darken-background" style={{opacity:.3}}></div>
      <div className="slider-picture">
        <Img fluid={data.image5.childImageSharp.fluid}/>
      </div>
      <div className="text">
        <h3>Trabajamos con profesionalidad y eficacia</h3>
        <p>Contamos con personal profesional que comprende las necesidades y expectativas de nuestros clientes.</p>
      </div>
    </div>
  </Carousel>
    </>
  )}
/>
)

export default CarouselContainer;
